import * as React from "react"
import {Link} from "gatsby"
import {Helmet} from "react-helmet";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 32,
  maxWidth: 320,
}

const headingStylesSmall = {
    display: "block",
    fontSize: 21,
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{
        lang: "ru",
      }}>
        <title>Ошибка 404 - страница не найдена</title>
      </Helmet>

      <main style={pageStyles}>
        <h1 style={headingStyles}>Ошибка 404 <small style={headingStylesSmall}>Страница не нейдена</small></h1>
        <p style={paragraphStyles}>
          <Link to="/">Вернуться на главную страницу</Link>
        </p>
      </main>
    </>
  )
}

export default NotFoundPage
